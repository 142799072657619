import { Controller } from "stimulus"

export default class extends Controller {
  onConfirm(e) {
    e.preventDefault();
    $('#js-consultant-modal-confirm-apply').modal('show');
    $('.js-confirm-url').attr('href', e.currentTarget.href);
  }

  removePartial(e) {
    if (e.detail[0].includes('user/sign_in')) return ;
    let param = e.currentTarget.search
    $(`a[href$="${param}"][data-action="click->confirm#onConfirm"]`)[0].remove();
    $('#js-consultant-modal-confirm-apply').modal('hide');
    toastr.success(I18n.t('javascript.job_board.confirm.remove'))
  }

  replacePartial(e) {
    if (e.detail[0].includes('user/sign_in')) return ;
    let param = e.currentTarget.search
    let element = $(`a[href$="${param}"][data-action*="click->confirm#onConfirm"]`)
    let classes;
    if (element.hasClass('btn-apply-now')) {classes = "btn-apply-disabled"}
    if (element.hasClass('btn-default')) {classes = "btn-disabled"}
    element.removeClass('btn-apply-now btn-default');
    element.addClass(classes);
    $('#js-consultant-modal-confirm-apply').modal('hide');
    toastr.success(I18n.t('javascript.job_board.confirm.add'))
  }
  removeFileCV(e) {
    let param = e.currentTarget.pathname
    $(`a[href$="${param}"][data-action="click->confirm#onConfirm"]`).closest('li').remove();
    $('#js-consultant-modal-confirm-apply').modal('hide');
    toastr.success(I18n.t('javascript.job_board.confirm.remove_file'))
  }
}
