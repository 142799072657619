const images = require.context("../images", true, /\.(png|jpg|jpeg|svg|ico)$/);
const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("controllers/job_board")

// Import turbolinks
import './job_board/init-turborlink';

// Import jquery
import jquery from "jquery";
window.$ = window.jquery = jquery;

import { I18n } from "i18n-js";
import translations from "../../../public/javascripts/translations.json"
window.I18n = new I18n();
window.I18n.store(translations);
window.I18n.enableFallback = true;

import "./job_board/main";
import "./job_board/tooltip";
import "@fortawesome/fontawesome-free/css/all";
import "../stylesheets/job_board";
import toastr from "toastr";

window.toastr = toastr;
toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-bottom-left",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "5000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};

$(document).ready(function(){
  // Scroll to job results when go to job page
  if (location.pathname === "/job") {
    var position = $("section.section-box-2").height() - $("header.sticky-bar").height();
    $('html, body').animate({ scrollTop: position - 22 }, 0);
  }
});
