import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'output', 'submitButton' ]

  connect() {
    this.onChange();
  }

  onSubmit(e) {
    e.preventDefault()
    if (!!this.outputTarget.checked == true) {
      e.currentTarget.closest('form').submit()
      e.currentTarget.classList.add('btn-disabled')
    } else {
      e.currentTarget.classList.add('bg-secondary')
    }
  }

  onChange(e) {
    if (!!this.outputTarget.checked == true) {
      this.submitButtonTarget.setAttribute("class" , "btn btn-brand-1 bg-primary w-100")
    } else {
      this.submitButtonTarget.setAttribute("class" , "btn btn-brand-1 bg-secondary w-100")
    }
  }
}
