require("turbolinks").start();

import preloadPage from '../../images/imgs/template/loading.gif'

if (Turbolinks.supported) {
  Turbolinks.ProgressBar.prototype.refresh = function() {}
  // custom css
  Turbolinks.ProgressBar.defaultCSS = ""

  // creates progress bar with custom markup
  Turbolinks.ProgressBar.prototype.installProgressElement = function() {
    return $('body').before($(`<div id="preloader-active"> <div class="preloader d-flex align-items-center justify-content-center"> <div class="preloader-inner position-relative"> <div class="text-center"></div> <img src="${preloadPage}"> </div> </div> </div>`));
  }

  // removes progress bar with custom markup
  Turbolinks.ProgressBar.prototype.uninstallProgressElement = function() {
    $(document).find('#preloader-active').remove();
  }

  // changes the default 500ms threshold to show progress bar
  Turbolinks.BrowserAdapter.prototype.showProgressBarAfterDelay = function() {
    return this.progressBarTimeout = setTimeout(this.showProgressBar, 50);
  };
}
