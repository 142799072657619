import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["occupationModal", "locationModal", "selectedOccupations", "selectedLocations"];
  emptyOccupationLabel = "";
  emptyLocationLabel   = "";

  connect() {
    var self = this;

    this.onloadOccupation();
    this.onloadLocation();
    this.onchangeCheckBox();
    this.onloadDefaultLabel();
    this.onloadSelectedLabel();

    $(this.occupationModalTargets).on("hidden.bs.modal", function () {
      self.setSelectedValueForLabel(this, self.selectedOccupationsTargets, self.emptyOccupationLabel);
    });

    $(this.locationModalTargets).on("hidden.bs.modal", function () {
      self.setSelectedValueForLabel(this, self.selectedLocationsTargets, self.emptyLocationLabel);
    });
  }

  onchangeCheckBox() {
    $(document).on('change', '.checkall__all, .checkall__item', () => {
      if ($('.js-md-occupation input[type="checkbox"]:checked').length > 0) {
        document.querySelector('.text-occupation').setAttribute('class', 'select2-selection__rendered text-occupation btn-occupation pe-1');
      } else {
        document.querySelector('.text-occupation').setAttribute('class', 'select2-selection__rendered text-occupation');
      }
    })

    $(document).on('change', '.checkall__all, .checkall__item', () => {
      if ($('.js-md-location input[type="checkbox"]:checked').length > 0) {
        document.querySelector('.text-location').setAttribute('class' , 'select2-selection__rendered text-location btn-occupation pe-1');
      } else {
        document.querySelector('.text-location').setAttribute('class' , 'select2-selection__rendered text-location');
      }
    })
  }

  onloadOccupation() {
    if ($('.js-md-occupation input[type="checkbox"]:checked').length > 0) {
      document.querySelector('.text-occupation').setAttribute('class' , 'select2-selection__rendered text-occupation btn-occupation pe-1');
    } else {
      document.querySelector('.text-occupation').setAttribute('class' , 'select2-selection__rendered text-occupation');
    }
  }

  onloadLocation() {
    if ($('.js-md-location input[type="checkbox"]:checked').length > 0) {
      document.querySelector('.text-location').setAttribute('class' , 'select2-selection__rendered text-location btn-occupation pe-1');
    } else {
      document.querySelector('.text-location').setAttribute('class' , 'select2-selection__rendered text-location');
    }
  }

  onloadSelectedLabel() {
    // Set selected occupations, locations to filter
    this.setSelectedValueForLabel(this.occupationModalTargets, this.selectedOccupationsTargets, this.emptyOccupationLabel);
    this.setSelectedValueForLabel(this.locationModalTargets, this.selectedLocationsTargets, this.emptyLocationLabel);
  }

  onloadDefaultLabel() {
    this.emptyOccupationLabel = $(this.selectedOccupationsTargets).text();
    this.emptyLocationLabel   = $(this.selectedLocationsTargets).text();
  }

  getSelectedValues(selectBoxModal) {
    var values = [];
    $(selectBoxModal).find("input.checkall__item:checked").each(function() {
      values.push($(this).parents().eq(1).find('span').first().text());
    })
    return values.join("、");
  }

  setSelectedValueForLabel(modal, label, emptylabel) {
    var selectedValues = this.getSelectedValues(modal);
    if (selectedValues == "") selectedValues = emptylabel;

    $(label).text(selectedValues);
  }
}
