import { Controller } from "stimulus";
import $ from 'jquery';
import { faBookDead } from "@fortawesome/free-solid-svg-icons";

export default class extends Controller {
  static targets = ['salary' , 'form']
  connect() {
    $('.js-salary').each((index, element) => {
      let number = element.value
      if (number) {
        element.value = this.convertCurrency(number)
      }
    })
  }

  // private

  replaceUnit(event) {
    event.currentTarget.value = this.convertCurrency(event.currentTarget.value)
    if (!this.maxLength(event.currentTarget.value)) {
      event.currentTarget.maxLength = event.currentTarget.value.length
    }
  }

  convertCurrency(currency) {
    if (!currency || currency == '円') { return ''; };
    currency = currency.replace(/[^0-9.]+/g,'')
    currency = Intl.NumberFormat('ja-JP',{ style: 'currency', currency: 'JPY', currencyDisplay: 'name' }).format(currency);
    return currency;
  }

  reset(event) {
    event.preventDefault()
    $('.js-form-reset input[type=checkbox]:checked').prop('checked', false)
    $('.js-form-reset input[type=text]').val('')
    $('.js-form-reset select').val('')
    $('.job_search')[0].submit()
  }
  maxLength (value, length = 9) {
    if (value.replace(/[^0-9.]+/g,'').length >= length) { return false;}
    return true;
  }
}
