import { Controller } from "stimulus"

export default class extends Controller {

  removePartial(e) {
    if (e.detail[0].includes('user/sign_in')) return ;
    $(e.currentTarget.closest('[data-controller]')).fadeOut()
    toastr.success(I18n.t('javascript.job_board.like.remove'))
  }

  replacePartial(e) {
    if (e.detail[0].includes('user/sign_in')) return ;
    let element = $(e.currentTarget).find('i.fa-star');
    if (element.hasClass('fa-regular')) {
      element.removeClass('fa-regular')
      element.addClass('fa-solid text-warning')
      toastr.success(I18n.t('javascript.job_board.like.add'))
    }
    else {
      element.removeClass('fa-solid text-warning')
      element.addClass('fa-regular')
      toastr.success(I18n.t('javascript.job_board.like.remove')) 
    }
  }
}
