import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "select" ]

  connect() {
    $('.js-select2-job-sort').select2({
      minimumResultsForSearch: Infinity
    })

    this.selectTarget.querySelectorAll('.select2').forEach(function(element) {
      element.style.width = '100%';
    });
  }
}
